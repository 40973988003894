<template>
  <div class="container">
    <div class="cell_box">
      <van-cell
          v-for="(item,index) in DataList"
          class="cell"
          :key="index"
          :title="item.name"
          is-link
          @click="jump(item)"
          value=""
      >
        <template #right-icon>
          <van-button icon="delete" @click.stop="del(item)" type="danger" size="mini" />
        </template>
      </van-cell>
    </div>
    <van-empty v-if="DataList.length === 0" description="暂无待您面试人员~" />
    <div class="info"><van-icon size="14" class="icon" name="info-o" />注意：面试过程中请对应聘者从三个维度（过往经验和能力、学习和成长能力、价值观契合度）做出考核！</div>
  </div>
</template>

<script>
import { GetApprovalList,delInterviewer } from "@/api/examiner";
import { Dialog } from 'vant';
export default {
  name: "ApprovalList",
  data(){
    return {
      DataList: []
    }
  },
  created() {
    this.$store.dispatch('changeLocation','待我审批')
    this.fetchData()
  },
  methods: {
    del(item){
      const { id } = item;
      Dialog.confirm({
        title: '提示',
        message: '确认删除该条应聘者的数据吗？',
      })
      .then(() => {
        const params = {
          openId: this.$store.state.info.openId,
          peo_id: [id]
        }
        delInterviewer(params).then(res =>{
          console.log(res);
          const {errcode,message} = res;
          if(errcode !== 200){
            this.$toast(message);
            return
          }
          this.$toast('删除成功');
          this.fetchData()
        })
      })
    },
    jump(item){
      this.$router.push({name: 'ApplicationDetails', query: {id: item.id}})
    },
    fetchData(){
      GetApprovalList({openId:this.$store.state.info.openId}).then(res => {
        if(res.errcode === 200){
          this.DataList = res.rows;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
p{
  margin: 0;
}
.container{
  .cell_box{
    .cell{
      width: 355px;
      height: 44px;
      margin: 15px auto;
      background: #FFFFFF;
      box-shadow: 0px 1px 7px 0px rgba(153, 153, 153, 0.25);
    }
  }
  .info{
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    background-color: #fcecec;
    margin-top: 30px;
    border:1px solid #ff3366;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FF3333;
    line-height: 21px;
    display: flex;
    align-items: center;
    .icon{
      margin-top: -20px;
      color: #ff3333;
      margin-right: 3px;
      border-radius: 50%;
    }
  }
}
</style>
